<template>    
    <div class="row job-config left-nav-collapsible" v-show="!loading && jobObj.created_at" > 
        <div v-if="detailPageOpen" id="detail-page-open">   
            <button class="btn details-open-btn" type="button" @click="onDetailPageOpenClose(false)">
                <span> <i class="fa fa-caret-right"></i></span>
            </button>
        </div> 
        <TransitionGroup :name="jobObj.created_at?'slide':''" key="detail">
            <div v-if="showCloseBtn && !detailPageOpen" id="detail-page-close" key="close">
                <button class="btn text-start details-open-btn" type="button" @click="onDetailPageOpenClose(true)">
                    <span> <i class="fa fa-caret-left"></i></span>
                </button>
            </div>
            <div v-if="!detailPageOpen" id="details-col" @mouseover="onDetailPageHover(true)" key="details-col">            
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-header-title">Files</h5>
                        <button type="button" data-bs-toggle="collapse" href="#collapse-files-div" aria-expanded="true" aria-controls="collapse-files-div" id="collapse-btn" class="btn  btn-sm"> <i class="fa fa-chevron-up float-end"></i></button>
                    </div>
                    <div id="collapse-files-div" class="collapse show" aria-labelledby="collapse-btn-1" >
                        <div class="card-body py-3">
                            <p v-if="!jobObj.system_files && !jobObj.user_files">No configuration files found.</p>
                            <list-group :title="'File List'" :list="jobObj.system_files" :activeListItem="activeTab"
                                listItemKey="relative_path" @selectBtnclick="showFile"></list-group>
                            <list-group :editMode="editMode" :title="'File List'" :list="jobObj.user_files"
                                :activeListItem="activeTab" listItemKey="relative_path" @deleteBtnClick="onFileDelete"
                                @selectBtnclick="showFile"></list-group>
                            <div v-if="editMode" class="mb-4">
                                <button v-if="!addingFile" @click="showAddFile" class="btn btn-sm btn-primary float-end" type="button">
                                    Add File
                                </button>
                                <div v-else>
                                    <form @submit.prevent="addFile">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="example.yaml" v-model="newFileName" autofocus>
                                            <button @click="addFile" class="btn btn-outline-secondary" type="submit">OK</button>
                                        </div>
                                    </form>
                                </div>
                            </div>                        
                        </div> 
                    </div>           
                    <div class="" v-if="isSandbox">
                        <div class="card-header">
                            <h5 class="card-header-title">Job Run Params</h5>
                            <button type="button" data-bs-toggle="collapse" href="#collapse-env-params-div" aria-expanded="true" aria-controls="collapse-env-params-div" id="collapse-btn-1" class="btn  btn-sm"> <i class="fa fa-chevron-up float-end"></i></button>
                        </div>
                        <div id="collapse-env-params-div" class="collapse show" aria-labelledby="collapse-btn-1" >
                            <div class="card-body pt-2 mb-4">
                                <div class=""> 
                                    <div class="form-check my-2" v-if="override">
                                        <input class="form-check-input" type="checkbox" v-model="override_capacity" id="override-input">
                                        <label class="form-check-label" for="defaultCheck1">
                                            Run with override
                                        </label>
                                    </div>
                                   
                                    <div class="form-group">
                                        <label ><h5>Tag</h5></label>
                                        <input type="text" class="form-control" id="tagInput" placeholder="Enter tag" v-model="tag">
                                    </div> 
                                    <div class="form-group">
                                        <label ><h5>Image</h5></label>
                                        <input  class="form-control" id="tagInput"  placeholder="Enter image" v-model="image"/>
                                    </div>
                                </div>
                                <h5 class="py-1">Env params</h5>
                                <div class="">                            
                                    <div class="table-responsive">
                                        <table class="table table-sm  table-nowrap">
                                            <thead>
                                                <tr class="">
                                                    <th class="col-sm-5">Param</th>
                                                    <th class="col-sm-5">Input/Macro</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(
                                                        queryParam, index
                                                    ) in envParams" :key="index">
                                                    <td class="col-sm-5 p-2">
                                                        <div v-if="!queryParam.name" >
                                                            <input style="padding: 1px 2px;" 
                                                                    class="form-control" type="text" 
                                                                    v-model="envParams[index].param"
                                                            />
                                                        </div>
                                                        <div v-else>
                                                            {{ queryParam.param }}
                                                        </div>
                                                    </td>
                                                    <td class="col-sm-5 p-2">
                                                        <div v-if="queryParam.disable">
                                                            {{ queryParam.input }}
                                                        </div>
                                                        <div v-else>
                                                            <input style="padding: 1px 2px;"
                                                                class="form-control" type="text" v-model="envParams[index].input" />
                                                        </div>
                                                    </td>
                                                    <td class="col-sm-1">
                                                        <i  @click="removeQueryParamField(index)" class="fa fa-regular fa-trash-can"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>                        
                                    </div>
                                    <div class="float-end mt-2">
                                        <button class="btn btn-sm btn-primary" @click="onAddQueryParam">Add Env Param</button>
                                    </div>
                                </div>  

                            </div>  
                        </div>   
                    </div>
                    <div class="">
                        <div class="card-header">
                            <h5 class="card-header-title">Details</h5>
                            <button type="button" data-bs-toggle="collapse" href="#collapse-props-div" aria-expanded="false" aria-controls="collapse-props-div" id="collapse-btn-2" class="btn  btn-sm collapsed"> <i class="fa fa-chevron-up float-end"></i></button>
                        </div>
                        <div id="collapse-props-div" class="collapse" aria-labelledby="collapse-btn-2" >
                            <div class="card-body pt-0">
                                <div class="list-group list-group-flush ">
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="">Type</h5>
                                            </div>
                                            <div class="col-12">
                                                <InputField v-if="isProdSandbox"
                                                    class="mb-0"
                                                    :editable="metaDataEdit"
                                                    :showLabel="false"
                                                    :options="jobTypeOptions"
                                                    type="select"
                                                    v-model="metadata.type"
                                                    />
                                                <InputField v-else
                                                    class="mb-0"
                                                    :editable="false"
                                                    :showLabel="false"
                                                    :options="jobTypeOptions"
                                                    type="select"
                                                    :modelValue="job.type?job.type.toLowerCase():''"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item" v-if="isProdV2Sandbox">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="">Promotion</h5>
                                            </div>
                                            <div class="col-12">
                                                <InputField
                                                    class="mb-0"
                                                    :editable="metaDataEdit"
                                                    :showLabel="false"
                                                    :options="promotionOptions"
                                                    type="select"
                                                    v-model="metadata.promotion"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="">Description</h5>
                                            </div>
                                            <div class="col-12">
                                                <InputField
                                                    class="mb-0"
                                                    :showLabel="false"
                                                    :editable="metaDataEdit"
                                                    type="textarea"
                                                    v-model="metadata.description"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item" v-if="job.kind">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">Kind</h5>
                                            </div>
                                            <div class="col-auto"><small class="text-muted">{{ job.kind }}</small></div>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">Author</h5>
                                            </div>
                                            <div class="col-auto"><small class="text-muted">{{ job.created_by }}</small></div>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">Created</h5>
                                            </div>
                                            <div class="col-auto"><small class="text-muted">{{ DateTimeFormat(job.created_at) }}</small>
                                                <span class="badge badge-secondary">{{ convertToRelativeTime(job.created_at) }}</span></div>
                                        </div>
                                    </div>
                                    <div class="list-group-item">
                                        <div class="row align-items-center">
                                            <div class="col">
                                                <h5 class="mb-0">Updated</h5>
                                            </div>
                                            <div class="col-auto"><small class="text-muted">{{ DateTimeFormat(job.updated_at) }}</small><span class="badge badge-secondary">{{ convertToRelativeTime(job.updated_at) }}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="float-end my-3" v-if="metaDataEdit">
                                        <button class="btn btn-sm btn-primary" @click="onUpdateMetadata">Update Metadata</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" id="main-col" @mouseover="onDetailPageHover(false)" key="main-col">
                <job-editor v-on:tabChange="showTab" v-on:onLintFailure="onLintFailure" :editMode="editMode"
                    :jobType="jobType" v-model="yamlContent" :activeTab="activeTab"></job-editor>
            </div>
        </TransitionGroup>
    </div>
    <div v-show="loading || !jobObj.created_at"  class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import macros from "../../../utils/macros";
import { mapActions, mapState } from "vuex";
import ListGroup from '../../../components/ListGroup.vue';
import JobEditor from '../../../components/sandbox/JobEditor.vue';
import { DateTimeFormat , relativeTime, deepEqual} from '../../../utils/commonFunction';
import { GenerateCurlCommand } from '../../../utils/curlCommand';
import InputField from "../../../components/InputField.vue";
dayjs.extend(duration)
export default {
    name: "Configuration",
    components: { ListGroup, JobEditor, InputField },
    data() {
        return {
            jobType: this.job.kind,
            selectedSandbox: this.$route.params.sandboxId,
            activeTab: "",
            yamlContent: {},
            jobObj: {},
            runClicked: false,
            response: '',
            error: '',
            isButtonDisabled: false,
            loading: false,
            addingFile: false,
            newFileName: '',
            name: "tagName",
            tagOptions: [],
            jobName: this.$route.params.jobId,
            dbJobSnapshot:{},
            detailPageOpen: false,
            showCloseBtn:false,
            override_capacity:false,
            metadata: {}

        };
    },
    props: ["editMode", "isSandbox", "job", "runJobTrigger", "saveJobTrigger", "saveAndJobTrigger", "cancelJobTrigger", "copyCurl","snapshot", "saveJobConfigTrigger","saveJobOverrideTrigger","override","overrideEditMode", "meters", "snapOverride"],
    watch: {
        runJobTrigger() {            
            this.runJob();
        },
        saveJobTrigger() {
            const runJobAfter = false;
            this.saveJob(runJobAfter, false);
        },
        saveAndJobTrigger() {
            const runJobAfter = true;
            this.saveJob(runJobAfter, false);
        },
        job() {
            this.initateYamlContent();
            this.initiateMetadata();
        },
        editMode(val) {
            if (val) {
                this.error = '';
                this.response = '';
            }
            this.addingFile = false;
            this.initateYamlContent();
        },
        copyCurl(){
            this.curlCommand()
        },
        override(val) {
            this.override_capacity = val?true:false;
        }          
    },
    computed: { 
        ...mapState({
            jobKinds: (state) => state.amdp.jobKinds,
            jobTypes: (state) => state.amdp.jobTypes,
        }), 
        isProdV2Sandbox() { //hideMetaData
            let prodv2Sandboxes = this.$store.state.sandbox.prodV2Sandboxes;
            prodv2Sandboxes = prodv2Sandboxes.filter((sandbox => sandbox.name === this.selectedSandbox))
            return prodv2Sandboxes.length? true: false;
        },
        isProdSandbox() { //hideMetaData
            let prodSandboxes = this.$store.state.sandbox.prodSandboxes;
            prodSandboxes = prodSandboxes.filter((sandbox => sandbox.name === this.selectedSandbox))
            return prodSandboxes.length? true: false;
        },
        jobTypeOptions() {
            return this.jobTypes?.map((type) => {
                return {
                label: type.job_type,
                value: type.job_type,
                };
            });
        }, 
        promotionOptions() {
            return [
                {label:"Allow",value: "allow"},
                {label:"Deny",value: "deny"},
            ]
        },  
        metaDataEdit() {            
            return this.$store.getters.userRoles.isAqferAdmin;
        },    
          
        tag: {
            get() {
                return this.$store.state.sandbox.jobRunParams[this.jobName].tag;
            },
            set(val) {
                this.$store.dispatch('sandbox/setJobTag',{jobName: this.jobName,tag: val});       
            }
        },
        image: {
            get() {
                return this.$store.state.sandbox.jobRunParams[this.jobName].image;
            },
            set(val) {
                this.$store.dispatch('sandbox/setJobImage',{jobName: this.jobName,image: val});       
            }
        },
        envParams: {
            get() {
                return this.$store.state.sandbox.jobRunParams[this.jobName].envParams;
            },
            set(val) {
                this.$store.dispatch('sandbox/setJobEnvParams',{jobName: this.jobName, envParams: val});       
            }
        }
        
    },    
    methods: {
        ...mapActions({
            readSandboxJob: "sandbox/readSandboxJob",
            updateSandboxJob: "sandbox/updateSandboxJob",
            updateSandboxJobMetadata: "sandbox/updateSandboxJobMetadata",
            readJob:"amdp/readJob",
            showModal: "showModal",
            fetchJobKinds: "amdp/fetchJobKinds",
            fetchJobTypes: "amdp/fetchJobTypes"
        }),        
        DateTimeFormat,
        generatedUrl() {                     
            let urlTemplate = `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/${this.$store.state.sandbox.currentSandbox}/jobs/${this.jobObj.name}/executions`
            if(this.tag)
                urlTemplate = `${urlTemplate}?tag=${this.tag}`;


            const hasParam = /[?&]/.test(urlTemplate); 
            if(this.override_capacity)
                urlTemplate = hasParam?`${urlTemplate}&override_capacity=true`:`${urlTemplate}?override_capacity=true`;
            
           
            return urlTemplate;
        },
        initiateMetadata(){
            this.metadata = {description: ""};
            if(this.isProdSandbox){
                this.metadata["type"]= this.job.type?(this.job.type).toLowerCase():'';
            }
            if(this.isProdV2Sandbox) {
                this.metadata = {
                    description: this.job.description,
                    promotion:this.job.promotion
                } 
            }
            
        },
        initateYamlContent() {
            this.jobObj = this.job
            
            let yamlContent = {};

            if (this.job.system_files) {
                for (const i of this.job.system_files) {
                    yamlContent[i.relative_path] = i.content
                }
            }
            if (this.job.user_files) {
                for (const i of this.job.user_files) {
                    yamlContent[i.relative_path] = i.content
                }
            }
            //Initially activeTab value is empty or undefined otherwise no need to reset to first value
            if(!this.activeTab || this.activeTab == "")
                this.activeTab = Object.keys(yamlContent)[0];
            this.yamlContent = JSON.parse(JSON.stringify(yamlContent));
        },
        async  saveJob(runJobAfter, skipCheck) {
            this.loading = true;
            let self = this;
            let toastoptions;
             //skipcheck - if call from modal to overwrite changes 
            let checkConfig = await this.checkConfigChanged(skipCheck)
            if(checkConfig){
                try {
                    let systemFileKeys = [];
                    let userFileKeys = [];

                    
                    for (const i of Object.keys(this.yamlContent)) {
                        let isSystemFile = false;
                        if(this.job.system_files) {
                            for (const j of this.job.system_files) {
                                if (j.relative_path == i)
                                    isSystemFile = true;
                            }

                        }
                        if (i.toLowerCase() == "vars.yaml") {
                            isSystemFile = true;
                        }     

                        if (isSystemFile)
                            systemFileKeys.push(i)
                        else
                            userFileKeys.push(i)
                    }

                    this.jobObj.system_files = systemFileKeys.map((key) => {
                        return { content: (typeof this.yamlContent[key] === 'string' ) ? this.yamlContent[key] : JSON.stringify(this.yamlContent[key]), relative_path: key };
                    });

                    this.jobObj.user_files = userFileKeys.map((key) => {
                        return { content: (typeof this.yamlContent[key] === 'string') ? this.yamlContent[key] : JSON.stringify(this.yamlContent[key]), relative_path: key };
                    });

                    const response = await this.updateSandboxJob(this.jobObj);
                    this.$emit('setSnapshot', this.jobObj);              
                    toastoptions = {
                        message: "Job updated successfully.",
                        type: "success"
                    }
                    if(runJobAfter) {
                        setTimeout(function(){
                            self.runJob();
                        }, 2000)
                        
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;

                    toastoptions = {
                        message: this.error || "Something went wrong when updating Job. Please contact support.",
                        type: "error"
                    }
                } finally {
                    this.loading = false;
                    this.$emit('triggerEditMode', false);
                    this.$store.dispatch('addToast', toastoptions);
                }
            }
            this.loading =false;

        },
        runJob() {
            this.$emit('setRunClick', true);
            const self = this;
            var payload = {};
            
            if(this.image)
                payload.image = this.image;
            
            if(this.envParams.length) {
                let envParamsObj = {};
                if (this.envParams) {
                    for (let i = 0; i < this.envParams.length; i++) {
                        const field = this.envParams[i];
                        if(field.input)
                        envParamsObj[field.param] = field.input;
                    }
                } 
                payload.env_params = envParamsObj;  
            }
            
            let generatedUrl = this.generatedUrl();

            // Make job run request
             const promise = axios
                .post(generatedUrl, payload, {
                    headers: {
                        'Authorization': `Bearer ${this.$store.state.accessToken}`
                    }
                })
                .then((res) => {                    
                    self.$store.dispatch("sandbox/fetchSandboxJobRuns", {jobName: self.jobName});
                    this.response = JSON.stringify(res.data, null, 4);
                    if (res.data.execution_id) {
                        let executionPath = `${this.$route.path}/${res.data.execution_id}`
                        executionPath = executionPath.replace("//", "/"); //prevent doubleslash
                        self.$router.push(`execution-details/${res.data.execution_id}`);
                    }
                })
                .catch((error) => {
                    self.error = error.message;
                    if (error.response.data && error.response.data.error) {
                        self.error += `: ${error.response.data.error}`
                    }

                    const toastoptions = {
                        message: "Something went wrong when running the Job. Please contact support.",
                        type: "error"
                    }                    
                    this.$store.dispatch('addToast', toastoptions);
                })
                .finally(() => {
                    this.$emit('setRunClick', false);
                })
        },
        curlCommand(){
            var payload = {};   
            if(this.image)
                payload.image = this.image;
            
            if(this.envParams.length) {
                let envParamsObj = {};
                if (this.envParams) {
                    for (let i = 0; i < this.envParams.length; i++) {
                        const field = this.envParams[i];
                        if(field.input)
                        envParamsObj[field.param] = field.input;
                    }
                } 
                payload.env_params = envParamsObj;  
            }        
            const headers ={'authorization': `Bearer ${this.$store.state.accessToken}`}
            let generatedUrl = this.generatedUrl();
            const curl =GenerateCurlCommand('POST',generatedUrl,headers,payload)
            this.showModal({component:"CopyCurl", data:{curl:curl}});
        },
        applyMacrosAndFormatting(value, replacements) {
            if (!value)
                return;

            value = macros.process(value, "~~xx~~", replacements);

            return "\\n    " + value.replaceAll("\n", "\\n    ");
        },
        showFile(file) {
            this.activeTab = file.relative_path
        },
        showTab(tab) {
            this.activeTab = tab;
        },
        showAddFile() {
            this.newFileName = '';
            this.addingFile = true;
        },
        addFile() {
            this.addingFile = false;

            if(!this.jobObj.user_files)
                this.jobObj.user_files = [];

            this.jobObj.user_files.push({
                relative_path: this.newFileName
            })
            this.yamlContent[this.newFileName] = "";
            this.activeTab = this.newFileName;
        },
        onLintFailure(isError) {
            this.$emit("onLintFailure", isError);
        },
        onFileDelete(file) { 
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete job config file",
                    message: `Are you sure you want to delete file ${file.relative_path}?`,
                    async confirm() {
                        self.jobObj.user_files = self.jobObj.user_files.filter(obj => obj.relative_path != file.relative_path);
                        delete self.yamlContent[file.relative_path];
                        if(self.activeTab === file.relative_path)
                            self.activeTab = Object.keys(self.yamlContent)[0];
                        return
                    },
                },
            });
        },
        onAddQueryParam() {
            this.envParams.push({
                name: null,
                param: null,
                input: null,
                deletable: true
            })
        },
        removeQueryParamField(index) {
            this.envParams.splice(index, 1);
        },
        async checkConfigChanged(skipCheck){
            let response;
            let self = this;
            try {
                if (skipCheck)
                    return true
                response = await this.readJob({sandboxName:this.$route.params.sandboxId,jobName:this.$route.params.jobId});
                let dbJob = response.data;
                if( dbJob.updated_at !== this.snapshot.updated_at){
                    let diff=[];
                   ["system_files","user_files"].map((key)=>{
                        //system_files or userfiles exits in server config or local snapshot
                        if(dbJob[key] || self.snapshot[key]){
                            //if system_files or user_file doesn't exits in snapshot set empty array
                            if(!self.snapshot[key])
                                self.snapshot[key]=[]
                            //if server config contain userfiles compare with snapshot and add to difference
                            if(dbJob[key]){
                                dbJob[key].map((file)=>{
                                let oldConfig = self.snapshot[key].find((snapfile) => snapfile.relative_path === file.relative_path);
                                //if snapshot doesn't contain the file then set empty files showing new file added
                                if (!oldConfig)
                                        oldConfig={relative_path:file.relative_path, content:''}
                                diff.push({current:file, prev: oldConfig})
                                })
                            }else{
                                //if snapshot contail file but server config doesn't means deleted the user files or system_files
                                self.snapshot[key].map((config)=>{ 
                                    diff.push({current:{relative_path:config.relative_path, content:''}, prev: config}) 
                                })
                            }
                        }
                    })
                    this.dbJobSnapshot = dbJob;
                    this.showModal({component:"ConfigChanges", data: {diff: diff, details:dbJob, onReload:this.onReloadConfig, onOverWrite:this.onOverWriteConfig}})	
                    return false
                }
                return true
            } catch (error) {
                if (error.response.data.error) this.error = error.response.data.error;
                else this.error = error.message;
                return false
            }
        },
        onReloadConfig(){
            this.$emit('setSnapshot', this.dbJobSnapshot); 
            this.$store.dispatch('hideModal');
            let toastoptions = {
                        message: "Reloaded Job Config successfully.",
                        type: "success"
            }
            this.$store.dispatch('addToast', toastoptions);
        },
        onOverWriteConfig(){
            this.saveJob(false,true)
            this.$store.dispatch('hideModal');
        },
        convertToRelativeTime(timestamp){
            return relativeTime(Date.now(), dayjs(timestamp));
        },
        onDetailPageOpenClose(value){
            this.showCloseBtn = false;
            this.detailPageOpen = value;
        },
        onDetailPageHover(value){
            this.showCloseBtn = value;
        },
        async onUpdateMetadata() { 
            let toastoptions;            
            try {
                const response = await this.updateSandboxJobMetadata({payload:this.metadata,jobName:this.jobName});
                toastoptions = {
                    message: "Job metadata updated successfully.",
                    type: "success"
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;

                toastoptions = {
                    message: this.error || "Something went wrong when updating Job metadata. Please contact support.",
                    type: "error"
                }
            } finally {                
                this.$store.dispatch('addToast', toastoptions);
            }      
            
        }
    },


    beforeMount() {
        this.initateYamlContent();
        this.$store.dispatch('sandbox/setDefaultJobRunParams',this.jobName);  
        if (!this.jobKinds.length) {
            this.fetchJobKinds();
        }
       
        if(this.jobTypes && !this.jobTypes.length) {
            this.fetchJobTypes();
        }
        this.initiateMetadata();
       

    },
    mounted() {        
        this.$emit('hideEdit', false);
        this.$emit('hideRunJob', false);
        this.$emit('showCancelJob', false);
        this.$emit('hidePromoteBtn', false);
        this.$emit('showOverrideEdit',false);
        this.$emit("hideSaveBtn", false);
    },
    emits: ['onLintFailure', 'setRunClick', 'triggerEditMode', 'hideEdit','hideSaveBtn','hideRunJob', 'showCancelJob', 'hidePromoteBtn', 'setSnapshot', 'setSaveConfigBtn','showOverrideEdit', 'setOverrideEditMode', 'setSnapOverride']

}
</script>

<style scoped>
.job-config .card-header {
    background-color: #F9FBFD;
    text-transform: uppercase;    
    color: #95AAC9;
    height:40px;
}
.job-config .collapsed .fa {
  transform: rotate(180deg);
}

</style>