<template>
    <basic-header pretitle="Aqfer graph managment" title="Single Node Visualizer" />
    <div class="m-5">
        <div class="row">
            <div class="col-12">
                <GraphVisualizer></GraphVisualizer>
            </div>
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import GraphVisualizer from "../../components/GraphVisualizer.vue";
export default {
    components: { BasicHeader, GraphVisualizer },
}
</script>

<style scoped></style>