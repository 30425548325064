<template>
    <div class="modal-card card">
        <form @submit.prevent="onCreateSandbox()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Create Sandbox
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="sandboxfields" v-model="sandBox" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="sandboxfields" v-model="sandBox" :loading="loading"/>
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions, mapState} from 'vuex'
import getSandboxFields from "../../utils/field-constants/sandboxCreateFields"
export default {
    components: { formFields, submitButton },
    data(){        
        return {
            error: "",
            sandBox: {},            
            sandboxfields: [],
            loading: false
        }
    },
    name: "AddSandbox",  
    async created(){
        this.sandboxfields = getSandboxFields(); 
        await this.fetchJobKinds();     
        this.sandboxfields = this.sandboxfields.map((field)=>{
            if(field.name =="enabled_job_kinds"){
                field.options= this.jobKindOption
            }
            return field;
        });
    }, 
    computed: { 
        ...mapState({
            jobKinds: (state) => state.amdp.jobKinds,
        }),
        jobKindOption(){
            return this.jobKinds.map((jobKind)=> {
                let name = jobKind.name.charAt(0).toUpperCase() + jobKind.name.slice(1)
                return { label:name, value: name.toUpperCase() } 
            })
        }
    }, 
    methods: {
        ...mapActions({
            createSandbox: "sandbox/createSandbox",
            fetchJobKinds: "amdp/fetchJobKinds",
        }),
        async onCreateSandbox() {
            this.loading = true;
            try {
                await this.createSandbox(this.sandBox)
            } catch (error) {                
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            } finally {
                this.loading = false;
            }
            

            this.$store.dispatch('hideModal');
            this.$router.push(`/${this.$store.state.currentClient.cid}/amdp/sandboxes/${this.sandBox.name}`);
        }
    }
};
</script>

<style>
</style>