const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Client name is required" }
        ]
    }, {
        name: "enabled",
        label: "Is Enabled",
        type: "checkbox",       
        editable: false,
    }, {
        name: "cid",
        label: "Client ID",
        type: "text",
        editable: false,
        validation: [
            { type: "required" }
        ]
    }, {
        name: "label",
        label: "Label",
        type: "text",
        editable: false,
        validation: [
            { type: "required" }
        ]
    }, {
        name: "akamaiProperty",
        label: "Akamai Property",
        tooltip:"Name of the property where the cid is deployed",
        type: "text",
        editable: true,
    }, {
        name: "cookieName",
        label: "Cookie Name",
        tooltip:"Name of the cookie used by the client for identifying their unique users.",
        type: "text",
        editable: true,
        validation: [
            { type: "required" }
        ],
    }, {
        name: "cookieDomain",
        label: "Cookie Domain",
        type: "text",
        editable: true,
        tooltip:"Domain on which the cookie will be set",
        validation:[
            {
                type:'regex',
                pattern:/^\.?(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]+$/,
                errMsg:"Allowed Characters: 'a-z0-9-'. Domain labels can only start and end with an alphanumeric character. The top level domain only accepts: a-z"
            }
        ]
    }, {
        name: "cookieFormat",
        label: "Cookie Format",
        type: "radio",
        editable: true,
        tooltip:"Format to generate the client cookie 1. uuidv4: hexadecimal 32 bytes long 2. aqfer24: hexadecimal 24 bytes long 3. aqfer: base64 encoded 24 bytes long 4. apache_moduid2: base64 encoded 21 bytes long 5. compact: base64 encoded 17 bytes long",
        visible: true,
        options: [
            { label: "aqfer", value: "aqfer" },
            { label: "aqfer24", value: "aqfer24" },
            { label: "apache_moduid2", value: "apache_moduid2" },
            { label: "compact", value: "compact" },
            { label: "uuidv4", value: "uuidv4" }
        ]
    }, {
        name: "cookieSameSite",
        label: "Cookie SameSite",
        type: "radio",
        editable: true,
        tooltip:"Set the Samesite attribute when configuring cookie",
        visible: true,
        options: [
            { label: "None", value: "None" },
            { label: "Strict", value: "Strict" },
            { label: "Lax", value: "Lax" },
        ]
    }, {
        name: "cookieSecure",
        label: "Cookie Secure",
        type: "checkbox",       
        editable: true,
        tooltip:"Add the Secure directive when setting cookie when enabled",

    },  {
        name: "cookieUrlsafe",
        label: "URL-Safe Cookie",
        type: "checkbox",       
        editable: true,
        tooltip:"Cookie is url safe if enabled"
    }, {
        name: "optoutCookie",
        label: "Opt-Out Cookie",
        type: "object",
        inputType: "json",
        editable: true,
        tooltip:"Cookie to set to opt out an user"
    }, {
        name: "pubDenyIds",
        label: "Deny Publisher IDs",
        type: "multiselect",
        editable: true,
        tooltip:"List of publisher ids for which the tag will not be loaded"
    }, {
        name: "pubDenyDomains",
        label: "Deny Publisher Domains",
        type: "multiselect",
        editable: true,
        tooltip:"List of publisher domains for which the tag will not be loaded"
    }, {
        name: "shouldSendImage",
        label: "Should Send Image",
        type: "checkbox",      
        editable: true,
        tooltip:"Flag to enable sending pixel image"
    }, {
        name: "notificationEmails",
        label: "Notification Email(s)",
        type: "multiselect",
        editable: true,
        tooltip:"List of email ids to which deployment information will be notified"
    }, {
        name: "dailyCountEmails",
        label: "Daily Count Email(s)",
        type: "multiselect",
        editable: true,
        tooltip:"List of email ids to which daily counts emails will be sent."
    }, {
        name: "dailyCountLimit",
        label: "Daily Count Limit",
        type: "number",
        editable: true,
        tooltip:"When sending daily count emails, any entry with counts below this limit is omitted"
    }, {
        name: "gdpr",
        label: "GDPR",
        type: "object",
        inputType: "json",
        tooltip:"Parameter name of TCF consent string",
        editable: true
    },  {
        name: "gpp.tcf.status",
        label: "TCF",
        tooltip:"TCF is applicable if gpp_sid=2: 1.BLOCKED:The traffic is blocked 2.DISABLED:TCF has been handled 3.ENABLED:TCF is handled",
        type: "radio",           
        editable: true,
        visible: true,
        options:[
            { label: "Disabled", value: "DISABLED" },
            { label: "Blocked", value: "BLOCKED" },
            { label: "Enabled", value: "ENABLED" }
        ]
    },
    {
        name: "gpp.tcf.gvlId",
        label: "TCF Gvl Id",
        tooltip: "TCF Global vendor List Identifier",                     
        editable: true,
        visible: true,
        defaultValue: null,
        validation: [
            { 
                type: "regex", pattern: "^(null|[0-9]+)?$",
                errMsg:"TCF Gvl Id should be a numeric value"
            }
        ]      
    },
    {
        name: "gpp.ccpa.status",
        label: "CPRA",
        tooltip:"CPRA is applicable if gpp_sid=6: 1.BLOCKED:The traffic is blocked 2.DISABLED:CPRA has been handled 3.ENABLED:CPRA is handled",
        type: "radio",           
        editable: true,
        visible: true,
        options:[
            { label: "Disabled", value: "DISABLED" },
            { label: "Blocked", value: "BLOCKED" },
            { label: "Enabled", value: "ENABLED" }
        ]
    },
    {
        name: "gpp.usMultiStatePrivacy",
        label: "Us Multi State Privacy",
        toolTip: "When US Multi states and US National privacy is applicable, 1.BLOCKED: The traffic is blocked 2.DISABLED: Privacy has been handled 3.ENABLED: Privacy is handled",
        type: "custom",           
        editable: true,
        customComponent: true,
        component: "UsMultiStatePrivacy"

    },
    {
        name: "customHeaders",
        label: "Custom Headers",
        type: "object",
        inputType: "json",
        tooltip:"List of custom headers to be served through the client's responses",
        editable: true,
    }, {
        name: "config",
        label: "Features",
        type: "object",
        tooltip:"Other configurations",
        inputType: "json",
        editable: true,
    }, 
];

export default Fields;

