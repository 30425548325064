<template>
    <div class="row mt-4" v-show="chartValue.length">
        <template v-for="(item) in chartValue" :key="item.index" >
            <div class="col-md-6" v-if="item.consumption > 0">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-header-title">{{ item.description }}</h2>
                    </div>
                    <div class="card-body">
                        <div class="row align-items-center gx-0">
                            <div class="col">
                                <h6 class="text-uppercase text-body-secondary mb-2">{{ item.unit }}
                                </h6>
                                <span class="h2 mb-0">{{ item.consumption.toFixed(1) }} / {{ item.quantity }}</span>
                                <div class="row align-items-center g-0">
                                    <div class="col-auto">
                                        <span class="h5 me-2 mb-0">{{ item.consumption_percentage.toFixed(1) }}%</span>
                                    </div>
                                    <div class="col">
                                        <div class="progress progress-sm me-4">
                                            <div class="progress-bar" role="progressbar"
                                                :style="{ width: item.consumption_percentage + '%' }"
                                                :aria-valuenow="item.consumption_percentage" aria-valuemin="0"
                                                aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <span class="h2 fa fa-coins text-body-secondary mb-0"></span>
                            </div>
                        </div>

                        <hr />
                        <div>
                            <canvas :ref="`chart${type + item.name}`" :id="`chart${type + item.name}`" class="chart-canvas"
                                width="843" height="400" style="
                                            display: block;
                                            box-sizing: border-box;
                                            height: 400px;
                                            width: 843px;
                                        ">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>

</template>
<script>
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    Utils
} from 'chart.js';
import { useAttrs } from 'vue';


Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

export default {
    props: {
        chartValue: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            charts: [],
        }
    },
    watch: {
        chartValue(value) {
            this.charts.map((mychart)=>{if(mychart) mychart.destroy();})
            this.$nextTick(() => {
                this.drawCharts()
            });
        }
    },
    methods: {
        drawCharts() {
            const horizontalLine = {
                id: 'horizontalLine',
                beforeDraw(chart, args, options) {
                    const ctx = chart.ctx;
                    const yScale = chart.scales['y'];
                    const yValue = options.limit;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(chart.chartArea.left, yScale.getPixelForValue(yValue));
                    ctx.lineTo(chart.chartArea.right, yScale.getPixelForValue(yValue));
                    ctx.strokeStyle = options.borderColor;
                    ctx.lineWidth = options.borderWidth;
                    ctx.setLineDash(options.borderDash || []);
                    ctx.lineDashOffset = options.borderDashOffset;
                    ctx.stroke();
                    ctx.restore();
                }
            };

            if (this.chartValue) {
                for (const usageData of this.chartValue) {
                    let datasets = []
                    if (usageData.sub_entitlements) {
                        for (const subEnt of usageData.sub_entitlements) {
                            // Stacked bars for sub-tenant usage if available
                            if (subEnt.tenant_usage) {
                                for (const tenantUsage of subEnt.tenant_usage) {                                
                                    datasets.push({
                                        label: tenantUsage.cid + " Usage",
                                        data: [{key: subEnt.name, cid: tenantUsage.cid, value: tenantUsage.consumption, usage: tenantUsage.usage}],
                                        parsing: {
                                            xAxisKey: 'key',
                                            yAxisKey: 'value'
                                        },
                                    })
                                }
                            }
                        }
                    }
                    //Entitlement POOLED CHART
                    const pooledChart = this.$refs['chart' + this.type + usageData.name]
                    const pooledData = {
                        datasets: datasets,
                    };
                    const pooledConfig = {
                        type: 'bar',
                        data: pooledData,
                        options: {
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                title: {
                                    display: false,
                                    text: 'Credits'
                                },
                                tooltip: {
                                    callbacks: {
                                        afterLabel: function(context) {
                                            let afterLabel = ""
                                            afterLabel += "Count: " + context.raw.usage.toLocaleString()
                                            return afterLabel;
                                        }
                                    }
                                },
                                horizontalLine: {
                                    borderColor: 'red',
                                    borderWidth: 2,
                                    borderDash: [5, 5],
                                    borderDashOffset: 2,
                                    limit: usageData.quantity,
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true
                                }
                            }
                        },
                        plugins: [horizontalLine]
                    };
                    
                    if (pooledChart) {
                        this.charts.push(new Chart(pooledChart, pooledConfig));
                    }
                }

            }
        },

    },
    beforeUnmount(){
        this.charts.forEach(chart => {
            if (chart && typeof chart.destroy === 'function') {
                chart.destroy();
            }
        });
    }
}
</script>
<style></style>