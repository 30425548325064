<template>
    <basic-header pretitle="Administration" title="Infrastructure Usage" :compact="true" />
    <div class="container-fluid">
        <div class="row g-3 align-items-center mt-3">
            <div class="col-auto">
                <InputField colField="mb-0" :editable="true" label="Month" :options="monthOptions"
                    type="select" :modelValue="usageMonth" @change="usageMonthChange($event)" />
            </div>
            <div class="col-auto">
                <InputField label="Start Date"  colField="mb-0" :modelValue="startDate" type="calendar"
                    :input-config="dateConfig"  @update:modelValue="startDateChange($event)"/>
            </div>
            <div class="col-auto">
                <InputField label="End Date"  colField="mb-0" :modelValue="endDate" type="calendar"
                    :input-config="dateConfig"  @update:modelValue="endDateChange($event)"/>
            </div>              
            <div class="col-auto" data-bs-theme="dark">
                <div class="form-group">
                    <label class="form-label">
                       Advanced
                    </label>
                    <div class="custom-control form-switch">
                        <input type="checkbox" :class="['form-check-input', { 'bg-secondary': !showAll }]"  id="customSwitch1" v-model="showAll">
                    </div>
                </div>
            </div>
        </div>
        <Tabs :tabs="tabItems">
            <template v-slot:daily>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 chart-view">
                                <div v-if="loading" class="text-center">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <Line v-else :data="dailyChartData" :options="chartOptions"/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </template>
            <template v-slot:aggregate>
                <div class="card">
                    <div class="card-body">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4" v-if="aggregateData">
                                <table class="table table-sm"  >
                                    <thead>
                                        <tr>
                                            <th>Key</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in aggregateTableData" :key="item.index">
                                            <td>{{ item.label }}</td>
                                            <td>{{ Intl.NumberFormat("en", {notation: "compact", roundingMode:"floor",
                                                maximumFractionDigits:2}).format(item.value) }} ({{infraMeterLabels[item.label]?.unit}})</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-sm-8 chart-view">
                                <Bar :data="aggregateChartData" :options="chartOptions"/>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Tabs>
    </div>
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
import InputField from "../../components/InputField.vue";
import Tabs from "../../components/Tabs.vue";
import { generateLastSixMonthOptions , getFirstDayOfMonth, getLastDayOfMonth} from '../../utils/commonFunction';
import { mapActions, mapState } from "vuex";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors } from 'chart.js';
import { Bar, Line } from 'vue-chartjs';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors)


export default {
    components: { Bar, Line, BasicHeader, InputField, Tabs },

    data() {
        return {
            loading: true,
            tabItems: [
                {                
                    name: "Daily",
                    id: "id1",
                    slot: "daily"
                },
                {                
                    name: "Aggregate",
                    id: "id2",
                    slot: "aggregate"
                },
            ],
            dateConfig: {
                wrap: true,
                dateFormat: 'Y-m-d'
            },
            showAll: false,            
            monthOptions:generateLastSixMonthOptions()
            
        }
    },

    methods: {
        ...mapActions({
            fetchUsageData: "admin/fetchInfraUsageData",
        }),
        async usageMonthChange(month) {
            this.$store.dispatch('admin/setUsageMonth',month);            
            const startDate = getFirstDayOfMonth(month);
            const endDate = getLastDayOfMonth(month);
            this.$store.dispatch('admin/setInfraUsageDataStartDate',startDate);
            this.$store.dispatch('admin/setInfraUsageDataEndDate',endDate);
            await this.getUsageData();
            
        },     

        async startDateChange(newValue) { 
            if(newValue !== this.$store.state.admin.infraUsageDataStartDate ) {
                this.$store.dispatch('admin/setUsageMonth','custom');   
                this.$store.dispatch('admin/setInfraUsageDataStartDate',newValue);
                await this.getUsageData();
            }
            
        },
        async endDateChange(newValue) {
            if(newValue !== this.$store.state.admin.infraUsageDataEndDate ) {
                this.$store.dispatch('admin/setUsageMonth','custom'); 
                this.$store.dispatch('admin/setInfraUsageDataEndDate',newValue)  
                await this.getUsageData();
            }
            
        },
        async getUsageData(){
            this.loading = true
            await this.fetchUsageData("daily");
            await this.fetchUsageData("aggregate");            
            this.loading = false

        }

    },

    

    computed: {
        ...mapState({
            startDate: state => state.admin.infraUsageDataStartDate,
            endDate: state => state.admin.infraUsageDataEndDate,
            usageMonth: state => state.admin.usageMonth,
            infraMeterLabels: state => state.admin.infraMeterLabels,
            usageDailyData: state => state.admin.infraUsageData.daily ? state.admin.infraUsageData.daily.data : [],
            usageDailyHeaders: state => state.admin.infraUsageData.daily ? state.admin.infraUsageData.daily.headers : [],
            aggregateData: state => state.admin.infraUsageData.aggregate ? state.admin.infraUsageData.aggregate.data : [],
            aggregateHeaders: state => state.admin.infraUsageData.aggregate ? state.admin.infraUsageData.aggregate.headers : []
        }),

        chartOptions() {
            const infraMeterLabels = this.infraMeterLabels;
            return {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    tooltip: {
                        callbacks: {
                            afterLabel: function(context) {
                                let label;
                                if (context.chart.config.type === 'bar') {
                                    // For bar charts, use context.label
                                    label = context.label;
                                } else if (context.dataset && context.dataset.label) {
                                    // For other chart types, use context.dataset.label
                                    label = context.dataset.label;
                                }

                                if (label && infraMeterLabels[label]) {
                                    return `(${infraMeterLabels[label].unit})`;
                                }
                                return "";
                            }
                        }
                    },
                    colors: {
                        forceOverride: true
                    }
                }
            }
        },
        meteredLabels() {
            return Object.entries(this.infraMeterLabels)
            .filter(([key, value]) => value.isMetered)
            .map(([key, value]) => (value.label));
        },

        nonMeteredLabels() {
            return Object.entries(this.infraMeterLabels)
            .filter(([key, value]) => !value.isMetered)
            .map(([key, value]) => ((value.label)));
        },       

        dailyChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            
            if (this.usageDailyData) {
                let labels = []
                let dataLabelsObj = {}
                let datasetsObj = {}

                // First pass: collect all unique dates and keys
                for (const dataPoint of this.usageDailyData) {
                    const date = dataPoint[this.usageDailyHeaders.indexOf('date')]
                    const dataLabel = dataPoint[this.usageDailyHeaders.indexOf('key')]
                    const value = dataPoint[this.usageDailyHeaders.indexOf('value')]

                    if (dataLabel && (this.showAll || this.meteredLabels.some(substring => dataLabel.includes(substring)))) {
                        // Add date to labels if not already present
                        if (!labels.includes(date)) {
                            labels.push(date)
                        }
                        
                        // Mark this dataLabel as existing
                        dataLabelsObj[dataLabel] = true
                        
                        // Initialize dataset object if it doesn't exist
                        if (!datasetsObj[dataLabel]) {
                            datasetsObj[dataLabel] = {}
                        }
                        
                        // Store the value indexed by date
                        datasetsObj[dataLabel][date] = value
                    }
                }

                // Sort the labels (dates)
                labels.sort()

                // Convert the datasets object to the required array format
                let datasets = Object.keys(dataLabelsObj).map(dataLabel => {
                    // Create an array of values matching the labels array
                    const data = labels.map(date => {
                        return datasetsObj[dataLabel][date] || 0
                    })

                    return {
                        label: dataLabel,
                        data: data
                    }
                })

                chartData = {
                    labels: labels,
                    datasets: datasets
                }
            }

            return chartData;
        },

        aggregateTableData() {
            var tableData = []
            for (const dataPoint of this.aggregateData) {
                var dataLabel = dataPoint[this.aggregateHeaders.indexOf('key')]
                var value = dataPoint[this.aggregateHeaders.indexOf('value')]
                if (dataLabel && (this.showAll || this.meteredLabels.some(substring => dataLabel.includes(substring)))) {
                    tableData.push({
                        label: dataLabel,
                        value: value
                    })
                }
            }
            return tableData
        },

        aggregateChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            if (this.aggregateData) {
                let labels = []
                let dataset = []
                

                for (const dataPoint of this.aggregateData) {
                    var dataLabel = dataPoint[this.aggregateHeaders.indexOf('key')]
                    var value = dataPoint[this.aggregateHeaders.indexOf('value')]
                    if (dataLabel && (this.showAll || this.meteredLabels.some(substring => dataLabel.includes(substring)))) {
                        labels.push(dataLabel)
                        dataset.push(value)
                    }
                }

                chartData = {
                    labels: labels,
                    datasets: [{ label: "Count", data: dataset }]
                }
            }

            return chartData
        }        
    },

    async beforeMount() {
        await this.getUsageData();
    },


}
</script>

<style scoped>
.chart-view{ 
  height: 30vh !important;  
}
</style>