import router from '../../router';
import axios from 'axios';
import dayjs from "dayjs";

const store = {
    namespaced: true,
    state: {
        users: [],
        clientList: [],
        tokens:[],
        clientTokens:[],
        clientProducts:[],
        encryptedUrlList:[],
        products:[],
        entitlements:[],
        productEntitlementList:[],
        jobTemplates:[],
        jobTemplateGroups:[],
        jobTemplateGroupMapList:[],
        infraUsageData: {},
        infraUsageDataStartDate: dayjs().startOf('month').format("YYYY-MM-DD"),
        infraUsageDataEndDate: dayjs().format("YYYY-MM-DD"),        
        usageMonth: dayjs().format('MM'),
        infraMeterLabels: {
            aMDPObjectStorage: {
              label: "aMDPObjectStorage",
              unit: "TB",
              isMetered: true
            },
            aMDPServerlessScan: {
              label: "aMDPServerlessScan",
              unit: "TB",
              isMetered: true
            },
            aMDPEgressBandwidth: {
              label: "aMDPEgressBandwidth",
              unit: "TB",
              isMetered: true
            },
            DynamoDBReadUnits: {
              label: "DynamoDBReadUnits",
              unit: "ReadRequestUnits",
              isMetered: false
            },
            DynamoDBWriteUnits: {
              label: "DynamoDBWriteUnits",
              unit: "WriteRequestUnits",
              isMetered: false
            },
            DynamoDBImportData: {
              label: "DynamoDBImportData",
              unit: "TB",
              isMetered: false
            },
            DynamoDBStorage: {
              label: "DynamoDBStorage",
              unit: "TB",
              isMetered: false  
            },
            GlueDPUHours: {
              label: "GlueDPUHours",
              unit: "DPU-Hour",
              isMetered: false  
            },
            QuickSightUsers: {
              label: "QuickSightUsers",
              unit: "Users",
              isMetered: false
            },
            GbHours: {
              label: "GbHours",
              unit: "GB-Hours",
              isMetered: false
            },
            CpuHours: {
              label: "CpuHours",
              unit: "vCPU-Hours",
              isMetered: false
            }
        }
        

    },
    mutations: {
        setUsers(state, users) {
            state.users = users
        },
        setClientList(state, clientList) {
            state.clientList = clientList
        },
        setTokens(state, tokens) {
            state.tokens = tokens
        },
        setClientTokens(state, clientTokens) {
            state.clientTokens = clientTokens
        },
        setEncryptedUrlList(state, encryptedUrlList) {
            state.encryptedUrlList = encryptedUrlList
        },
        setClientProducts(state, clientProducts) {
            state.clientProducts = clientProducts
        },
        setProducts(state, products) {
            state.products = products
        },
        setEntitlements(state, entitlements) {
            state.entitlements = entitlements
        },
        setProductEntitlementList(state, productEntitlementList) {
            state.productEntitlementList = productEntitlementList
        },
        setJobTemplates(state, jobTemplates) {
            state.jobTemplates = jobTemplates
        },
        setJobTemplateGroups(state, jobTemplateGroups) {
            state.jobTemplateGroups = jobTemplateGroups
        },
        setJobTemplateGroupMapList(state, jobTemplateGroupMapList) {
            state.jobTemplateGroupMapList = jobTemplateGroupMapList
        },
        setInfraUsageData(state, data) {
            state.infraUsageData = data
        },
        setInfraUsageDataStartDate(state, startDate) {
            state.infraUsageDataStartDate = startDate
        },
        setInfraUsageDataEndDate(state, endDate) {
            state.infraUsageDataEndDate = endDate
        },
        setUsageMonth(state, month) {
            state.usageMonth = month
        },
    },
    actions: {
        async createClient({rootState}, clientPayload) {
            return axios.post(rootState.mgmtUrl + "/v1/staging/clients", clientPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then(function(data) {
                const cidObj = {name: clientPayload.name, cid: clientPayload.cid}
                return axios.put(rootState.authUrl + '/v1/clients/' + cidObj.cid, cidObj, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
                }).then(function(data) {
                    //add new client to current user's client list
                    return axios.get(rootState.authUrl +  '/v1/users/' + rootState.currentUser.email, {
                        headers: {
                            Authorization: `Bearer ${rootState.accessToken}`,
                        },
                    }).then((response) => {
                        if(response.data.cids){
                            response.data.cids.push(cidObj.cid)
                            return axios.put(rootState.authUrl +  '/v1/users/' + rootState.currentUser.email, response.data, {
                                headers: {
                                    Authorization: `Bearer ${rootState.accessToken}`,
                                },
                            })
                        }
                    });
                })
            })
        },
        async updateClient({rootState}, clientPayload) {
            const eTag = clientPayload.eTag
            delete clientPayload.eTag
            return axios.put(rootState.mgmtUrl + "/v1/staging/clients/" + clientPayload.cid, clientPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                    'If-Match': eTag,
                },
            })
        },
        async deleteClient({rootState}, clientPayload) {
            return axios.delete(rootState.mgmtUrl + "/v1/staging/clients/" + clientPayload.cid, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then(function(data) {
                return axios.delete(rootState.authUrl + '/v1/clients/' + clientPayload.cid, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
                })
            })
        },
        async readClient({rootState}, id) {
            return axios.get(rootState.mgmtUrl + `/v1/staging/clients/${id}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async enableDisableClient({rootState}, clientPayload) {
            return axios.put(rootState.mgmtUrl + `/v1/clients/${clientPayload.cid}/enabled/${clientPayload.enabled}`, null, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async createUser({rootState, dispatch}, userPayload) {
            if(!userPayload.cids) userPayload.cids = [""]
            return dispatch('updateUser', userPayload).then(function(data) {
                return axios.post(rootState.mgmtUrl + "/v1/users/" + userPayload.email + "/send_welcome_email", {}, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
                })
            });
        },
        async updateUser({rootState}, userPayload) {
            if(!userPayload.cids) userPayload.cids = [""]
            return axios.put(rootState.authUrl + "/v1/users/" + userPayload.email, userPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteUser({rootState}, userPayload) {
            return axios.delete(rootState.authUrl + "/v1/users/" + userPayload.email, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readUser({rootState}, id) {
            return axios.get(rootState.authUrl + "/v1/users/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async generateToken({rootState},userPayload) {
            //this endpoint is for client_admin
            let url=`/v1/clients/${rootState.currentClient.cid}/refresh_tokens?`
            if(rootState.currentUser.roles.includes("aqfer_admin")){
                //this endpoint is for aqfer_admin
                url="/v1/refresh_tokens"
            }
            return axios.post(rootState.authUrl + url , userPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }) 
        },
        async createEncryptedUrl({rootState}, payload) {
            return axios.post(rootState.surfaceUrl + "/v1/helpjuice_urls", payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        },
        async updateEncryptedUrl({rootState}, payload) {
            return axios.put(rootState.surfaceUrl + "/v1/helpjuice_urls/" + payload.id, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteEncryptedUrl({rootState}, id) {
            return axios.delete(rootState.surfaceUrl + "/v1/helpjuice_urls/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readEncryptedUrl({rootState}, id) {
            return axios.get(rootState.surfaceUrl + "/v1/helpjuice_urls/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        fetchUsers({rootState, commit}) {
            axios.get(rootState.authUrl + "/v1/users", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const users = response.data;
                commit('setUsers', users)
            });
        },
        fetchClientList({rootState, commit}){
            axios.get(rootState.mgmtUrl + "/v1/staging/clients?detail=pixel", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                if(response.data.items){
                    const clientList = response.data.items;
                    commit('setClientList', clientList)
                }
            });
        },
        async fetchRefreshTokens({rootState, commit}) {
            return axios.get(rootState.authUrl + "/v1/refresh_tokens", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const tokens = response.data;
                commit('setTokens', tokens)
                return tokens
            });
        },
        async fetchAllRefreshTokens({rootState, state, dispatch, commit}) {
            dispatch('fetchRefreshTokens').then((refreshtokens)=>{
                axios.get(rootState.authUrl + "/v1/refresh_tokens?revoked=true", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const revokedtokens = response.data;
                commit('setTokens', [...refreshtokens,...revokedtokens])
                })
            });
        },
        async fetchClientRefreshTokens({rootState, commit}) {
            return axios.get(rootState.authUrl + `/v1/clients/${rootState.currentClient.cid}/refresh_tokens`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const tokens = response.data ? response.data: [];
                commit('setClientTokens', tokens)
                return tokens
            });
        },
        async fetchClientsAllRefreshTokens({rootState, dispatch, commit}) {
            dispatch('fetchClientRefreshTokens').then((refreshtokens)=>{
                axios.get(rootState.authUrl + `/v1/clients/${rootState.currentClient.cid}/refresh_tokens?revoked=true`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const revokedtokens = response.data ? response.data: [];
                commit('setClientTokens', [...refreshtokens,...revokedtokens])
                })
            });
        },
        fetchEncryptedUrlList({rootState, commit}){
            axios.get(rootState.surfaceUrl + "/v1/helpjuice_urls", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                if(response.data){
                    const encryptedUrlList = response.data;
                    commit('setEncryptedUrlList', encryptedUrlList)
                }
            });
        },
        async readToken({rootState}, id) {
             //this endpoint is for client_admin
             let url=`/v1/clients/${rootState.currentClient.cid}/refresh_tokens/${id}`
             if(rootState.currentUser.roles.includes("aqfer_admin")){
                  //this endpoint is for aqfer_admin
                 url=`/v1/refresh_tokens/${id}`
             }
            return axios.get(rootState.authUrl + url, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async revokeToken({rootState}, id){
             //this endpoint is for client_admin
            let url=`/v1/clients/${rootState.currentClient.cid}/refresh_tokens/${id}`
            if(rootState.currentUser.roles.includes("aqfer_admin")){
                 //this endpoint is for aqfer_admin
                url=`/v1/refresh_tokens/${id}`
            }
            return axios.delete(rootState.authUrl + url, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async fetchClientProducts({rootState, commit},cid) {
            return axios.get(rootState.clientMgmtUrl + `/v1/clients/${cid}/products`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const products = response.data ? response.data: [];
                commit('setClientProducts', products)
                return products
            });
        },
        async addClientSubscription({rootState}, payload) {
            return axios.post(rootState.clientMgmtUrl + `/v1/clients/${payload.cid}/products`, payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        },
        async updateClientSubscription({rootState}, payload) {
            return axios.put(rootState.clientMgmtUrl +  `/v1/clients/${payload.cid}/products/` + payload.id, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteClientProduct({rootState}, payload) {
            return axios.delete(rootState.clientMgmtUrl +  `/v1/clients/${payload.cid}/products/` + payload.id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        fetchEntitlements({rootState, commit}) {
            axios.get(rootState.clientMgmtUrl + "/v1/entitlements", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const entitlements = response.data;
                if(entitlements)
                    commit('setEntitlements', entitlements)
            });
        },
        async createEntitlement({rootState}, payload) {
            return axios.post(rootState.clientMgmtUrl + "/v1/entitlements", payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        },
        async updateEntitlement({rootState}, payload) {
            return axios.put(rootState.clientMgmtUrl + "/v1/entitlements/" + payload.entitlement_id, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteEntitlement({rootState}, id) {
            return axios.delete(rootState.clientMgmtUrl + "/v1/entitlements/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readEntitlement({rootState}, id) {
            return axios.get(rootState.clientMgmtUrl + "/v1/entitlements/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        fetchProducts({rootState, commit}) {
            axios.get(rootState.clientMgmtUrl + "/v1/products", {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const products = response.data;
                if(products)
                    commit('setProducts', products)
            });
        },
        async createProduct({rootState}, payload) {
            return axios.post(rootState.clientMgmtUrl + "/v1/products", payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        },
        async updateProduct({rootState}, payload) {
            return axios.put(rootState.clientMgmtUrl + "/v1/products/" + payload.product_id, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteProduct({rootState}, id) {
            return axios.delete(rootState.clientMgmtUrl + "/v1/products/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readProduct({rootState}, id) {
            return axios.get(rootState.clientMgmtUrl + "/v1/products/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        getProductEntitlementList({rootState, commit }, query) {
            return axios.get(rootState.clientMgmtUrl + "/v1/product_entitlements?"+query, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const list = response.data;
                if(list)
                    commit('setProductEntitlementList', list)
                return response
            }).catch((error) =>{
                commit('setProductEntitlementList', [])
                return []
            })
        },
        async addProductEntitlement({rootState}, payload) {
            return axios.post(rootState.clientMgmtUrl + "/v1/product_entitlements", payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        },
        async updateProductEntitlement({rootState}, payload) {
            return axios.put(rootState.clientMgmtUrl + "/v1/product_entitlements/" + payload.id, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteProductEntitlement({rootState}, id) {
            return axios.delete(rootState.clientMgmtUrl + "/v1/product_entitlements/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        getSubEntitlementList({rootState, commit }, entitlementId) {
            return axios.get(rootState.clientMgmtUrl + `/v1/sub_entitlements/${entitlementId}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const list = response.data;
                if(list)
                    commit('setProductEntitlementList', list)
                return response
            }).catch((error) =>{
                commit('setProductEntitlementList', [])
                return []
            })
        },
        async fetchJobTemplates({rootState, commit}) {
            axios.get(rootState.surfaceUrl + `/v1/job_templates`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const jobTemplates = response.data ? response.data: [];
                commit('setJobTemplates', jobTemplates)
            });
        },
        async createJobTemplate({rootState}, payload) {
            return axios.post(rootState.surfaceUrl + "/v1/job_templates", payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        },
        async updateJobTemplate({rootState}, payload) {
            return axios.put(rootState.surfaceUrl + "/v1/job_templates/" + payload.id, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteJobTemplate({rootState}, id) {
            return axios.delete(rootState.surfaceUrl + "/v1/job_templates/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readJobTemplate({rootState}, id) {
            return axios.get(rootState.surfaceUrl + "/v1/job_templates/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async fetchJobTemplateGroups({rootState, commit}) {
            axios.get(rootState.surfaceUrl + `/v1/job_template_group`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const jobTemplateGroups = response.data ? response.data: [];
                commit('setJobTemplateGroups', jobTemplateGroups)
            });
        },
        async createJobTemplateGroup({rootState}, payload) {
            return axios.post(rootState.surfaceUrl + "/v1/job_template_group", payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        },
        async updateJobTemplateGroup({rootState}, payload) {
            return axios.put(rootState.surfaceUrl + "/v1/job_template_group/" + payload.id, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteJobTemplateGroup({rootState}, id) {
            return axios.delete(rootState.surfaceUrl + "/v1/job_template_group/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readJobTemplateGroup({rootState}, id) {
            return axios.get(rootState.surfaceUrl + "/v1/job_template_group/" + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async getJobTemplateGroupMappings({rootState, commit}, job_template_id) {
            return axios.get(rootState.surfaceUrl + `/v1/job_template_group/${job_template_id}/job_template`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                const jobTemplateGroupMapList = response.data ? response.data: [];
                commit('setJobTemplateGroupMapList', jobTemplateGroupMapList)
                return response
            });
        },
        async createJobTemplateGroupMapping({rootState}, payload) {
            return axios.post(rootState.surfaceUrl +`/v1/job_template_group/${payload.job_template_group_id}/job_template`, payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        },
        async updateJobTemplateGroupMapping({rootState}, payload) {
            return axios.put(rootState.surfaceUrl + `/v1/job_template_group/${payload.job_template_group_id}/job_template/${payload.job_template_id}`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteJobTemplateGroupMapping({rootState}, payload) {
            return axios.delete(rootState.surfaceUrl +`/v1/job_template_group/${payload.job_template_group_id}/job_template/${payload.job_template_id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readJobTemplateGroupMapping({rootState}, payload) {
            return axios.get(rootState.surfaceUrl + `/v1/job_template_group/${payload.job_template_group_id}/job_template/${payload.job_template_id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        fetchInfraUsageData({rootState, dispatch, state, commit}, usageType) {
            // Reset only the specific usageType portion of infraUsageData
            commit('setInfraUsageData', { 
                ...state.infraUsageData, 
                [usageType]: {} 
            });

            let payload = {
                startDate: state.infraUsageDataStartDate,
                endDate: state.infraUsageDataEndDate,
                product: 'infrastructure',
                usageType: usageType                
            };
            dispatch('usage/getProductUsage', payload, { root: true }).then((data) => {
                if(data && data[payload.product]) {
                    // Update only the specific usageType portion while preserving other data
                    commit('setInfraUsageData', {
                        ...state.infraUsageData,
                        [usageType]: data[payload.product]
                    });
                }
            });            
        },
        setInfraUsageDataStartDate({commit}, startDate) {
            commit('setInfraUsageDataStartDate', startDate);
        },
        setInfraUsageDataEndDate({commit}, endDate) {
            commit('setInfraUsageDataEndDate',endDate);
        },
        setUsageMonth({commit}, month) {
            commit('setUsageMonth', month)
        },
    }
}

export default store