<template>
    <div>         
        <div class="card">  
            <div class="row justify-content-end">                                    
                <div  class="col-sm-3 d-flex position-absolute mt-3 me-3 justify-content-end" >
                    <div class="input-group input-group-sm">
                        <label class="input-group-text">Executions Limit</label>
                        <input type="number" class="form-control" aria-label="limit" v-model="$store.state.sandbox.executionsListLimit">
                        <button :disabled="jobRunList===null" class="btn btn-outline-secondary" type="button" @click="readJobRunList"><i class="fa fa-refresh" aria-hidden="true"></i></button>                    
                    </div>
                </div>           
            </div> 
                    
            <datatable             
                :tableId="`${job.name}-runhistory`" 
                :columns="columns"
                :formatColumn="formatColumn"
                :data="jobRunList"      
                :showLoading="jobRunList===null"       
                :compact="true" 
                :orderColumn="orderColumn"  
                @viewModal="openModal" 
                :viewModalColumn="viewModalColumn"            
                :detailColumn="{renderBaseUrl: (data) => {
                                                        return `execution-details/${data.execution_id}`;                                                   
                                                    },}"
            />
        </div>    
    </div>  
</template>

<script>
import Datatable from "../../../components/Datatable.vue";
import { mapActions, mapState } from "vuex";
import { secondsTohms } from '../../../utils/commonFunction';
export default {
    name: "Run History",
    components: { Datatable },
    data() {
        return {
            columns: [
                { data: "id"},
                { data: "status" },   
                { data: "logs" },          
                { data: "submitted_at", title: "submitted At"},
                { 
                    data: "run_time", 
                    title:"run time",
                    render: function (data, type, row) {
                        return secondsTohms(data)
                    },
                },
                { data: "tag" },
            ],
            orderColumn: [4, "desc"],
            formatColumn:[
                {
                    index:4,
                    type:"datetime"
                }
                
            ],
            viewModalColumn: 3 //view icon column number
        };
    },
    props:[
        "job",
        "isSandbox",
    ],  
    computed: {
        jobRunList() {
            let runList = this.$store.state.sandbox.sandboxJobRuns;
            if(runList) {
                const modifiedList = this.addStatusFlag(runList);
                return modifiedList;
            }
            return runList;
           
        }
    },
    methods: {
      ...mapActions({
            showModal: "showModal",
            fetchJobRuns: "amdp/fetchJobRuns",
            fetchSandboxJobRuns: "sandbox/fetchSandboxJobRuns",         
        }),
        async readJobRunList() {
            if (!this.job.name)
                return; 
            let self = this;   
            let response;
            try {
                response = await this.fetchSandboxJobRuns({jobName:this.job.name, limit:this.$store.state.sandbox.executionsListLimit});
            } catch (error) {
                if(error.response.status == 504) {
                    const options = {
                        message: "We’re sorry, but we’re having trouble processing your request.. Please try refreshing the list by setting limit to lower value",
                        type: "error",
                    };
                    this.$store.dispatch("addToast", options);
                } else {
                    if (error.response && error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;

                    let toastOptions = {
                        message: this.error || "Something went wrong while fetching executions list. Please contact support.",
                        type: "error",
                    };
                    this.$store.dispatch("addToast", toastOptions);      
                }
                return;
            }            
        },
        addStatusFlag(list) {
            let modifiedList = [];
            list.map((job)=>{
                    let status ="";
                    if(job.status.toLowerCase()==="succeeded"){
                        status = "<span class='text-success'>●</span> ";
                    } else if(job.status.toLowerCase()==="failed"){
                        status = "<span class='text-danger'>●</span> ";
                    } else if(job.status.toLowerCase()==="running"){
                        status = "<span class='text-primary'>●</span> ";
                    } else if(job.status.toLowerCase()==="submitted"){
                        status = "<span class='text-warning'>●</span> ";
                    } else if(job.status.toLowerCase()==="cancelled"){
                        status = "<span class='text-secondary'>●</span> ";
                    }
                    job["status"] = status + job.status;
                    modifiedList.push(job);
                });
            return modifiedList;
        },
        openModal(data){
            this.showModal({component:"ViewLog",data:{
                sandboxName:this.$store.state.sandbox.currentSandbox, jobName:this.job.name, executionId:data.execution_id,
                screenlarge: true,
                status:data.status.match(/\brunning\b/i)?"running":""
            }})
        }

    },       
    mounted() {  
        this.$emit('hideEdit', true);
        this.$emit('hideRunJob', true);
        this.$emit('hidePromoteBtn', true);
        this.$emit('showOverrideEdit', false);
        this.$emit('setOverrideEditMode', false);
    },
    emits: ['hideEdit', 'hideRunJob', 'hidePromoteBtn', 'showOverrideEdit', 'setOverrideEditMode']  

}
</script>

<style>

</style>